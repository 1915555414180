$color-white: #FFFFFF;
$color-white-bluish: #F3F4f9;
$color-white-bluish-dark: #E4F0F4;
$color-white-faded: rgba(255,255,255,.9);
$color-blue-dark: #17335B;
$color-blue-darkest: #14345C;
$color-blue-turquoise: #61DAFB;
$color-blue-turquoise-dark: #29AFD7;
$color-green-light: #2DB9AD;
$color-success: #56B5AD;
$color-failure: #FD8540;

$colors:
    'white' $color-white,
    'white-bluish' $color-white-bluish,
    'white-bluish-dark' $color-white-bluish-dark,
    'white-faded' $color-white-faded,
    'blue-dark' $color-blue-dark,
    'blue-darkest' $color-blue-darkest,
    'blue-turquoise' $color-blue-turquoise,
    'blue-turquoise-dark' $color-blue-turquoise-dark,
    'green-light' $color-green-light,
    'success' $color-success,
    'failure' $color-failure,
;

// TODO: Future colors should be in a map, and incrementally ranged in a 100 - xxx scale
@each $i in $colors {
    .color-#{nth($i, 1)}-bg {
        background-color: nth($i, 2);
    }
    .color-#{nth($i, 1)} {
        color: nth($i, 2);
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Gotham_Bold', 'Raleway', sans-serif;
    font-weight: 700;
    margin: 0 0 1rem 0;
    line-height: 1.2;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2rem;
    margin: 0 0 1rem 0;
    padding: 0;
    color: $color-blue-darkest;
    font-weight: 700;
}

.app-content {
    background-color: $color-white-faded;
}

.app-section {
    padding: 5rem 1.5rem;
}

.verification-content {
    margin-top: 20px;
    margin-bottom: 40px;
}

.navbar-item {
    font-family: Arial, sans-serif;
}

a.navbar-item:hover {
    background: transparent;
}

.message-box-margin {
    margin: .5rem;
}

.message-margin {
    margin-top: 20px;
}

.status-icon {
    margin-bottom: 40px;
    font-size: 7rem;
}

.step-icon {
    margin: 8px;
}

.step-icon.active {
    color: $color-blue-turquoise-dark;
}

.fa-check-circle {
    margin-right: .5rem;
}

.checklist {
    padding: 1.5rem 1.5rem 0 1.5rem;
}

.checklist li {
    display: flex;
    align-items: center;
    padding: .5rem 0;
}
