.site-header nav.navbar {
  background: transparent;
  padding: 1rem;
  box-shadow: none;
  position: absolute;
  width: 100%; }

.site-header nav.navbar:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15); }

.site-header nav.navbar.is-opaque {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15); }

.site-header .navbar-brand .navbar-item {
  padding: 0 0.75rem; }

.site-header .navbar-brand .navbar-item img {
  max-height: 40px; }

.navbar-end a {
  color: #333;
  font-size: .9rem;
  font-weight: 700; }

.cta {
  font-family: "Gotham Black", 'Raleway', sans-serif;
  border: 3px solid #E57E3E;
  background-color: #fff;
  color: #E57E3E;
  border-radius: 30px; }

a.cta.header-phone {
  font-family: "Gotham Black", 'Raleway', sans-serif;
  border: 3px solid #E57E3E;
  background-color: #fff;
  color: #E57E3E;
  border-radius: 30px;
  margin: .75rem .25rem 0 .75rem;
  padding-left: .75rem;
  padding-right: .75rem; }

.site-header .vmd-extra-navigation {
  text-align: center;
  margin: 0 0 0 1rem; }

.vmd-extra-navigation a {
  font-family: "Gotham Black",'Raleway',sans-serif;
  border: 3px solid #e57e3e;
  background-color: #fff;
  color: #e57e3e;
  border-radius: 30px;
  width: 100%; }

.site-nav .hs-menu-wrapper.hs-menu-flow-horizontal > ul {
  margin: 0;
  padding: 0; }

.site-nav .hs-menu-wrapper.hs-menu-flow-horizontal > ul li {
  float: left; }

.site-nav .hs-menu-wrapper.hs-menu-flow-horizontal > ul li a {
  display: block;
  max-width: 100%;
  line-height: 1;
  text-overflow: inherit;
  padding: 12px 12px 8px; }

@media screen and (min-width: 1024px) {
  .site-header .navbar-brand,
  .site-header .site-nav,
  .site-header .navigation-item {
    display: flex;
    align-items: center; }
  a.header-phone {
    display: none; } }

@media screen and (max-width: 1023px) {
  .site-nav .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-menu-depth-1 {
    display: block;
    width: 100%; }
  .vmd-extra-navigation a {
    margin-top: 0.5rem; } }

@media only screen and (max-width: 600px) {
  .navbar-brand a.navbar-item {
    width: 55%;
    padding: 0 !important; }
  .navbar-burger.burger {
    padding: 1rem .2rem; }
  .navbar-item.hide-mobile {
    display: none; }
  .vmd-extra-navigation {
    display: block; } }

.navbar-menu-button {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer; }

.navbar-menu-button:hover {
  background: #f2f2f2; }

@media screen and (min-width: 1024px) {
  .navbar-menu-button {
    display: none; } }

.availability {
  height: 100vh; }

.vmd-footer {
  background: #17335b; }
  .vmd-footer a,
  .vmd-footer .vmd-footer-copyright {
    color: #fff;
    font-family: Arial,sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6; }
    .vmd-footer a:hover,
    .vmd-footer .vmd-footer-copyright:hover {
      color: #fff; }
  .vmd-footer h3.footer-cta-title {
    font-family: Gotham_Book, Gotham,Raleway,sans-serif;
    font-size: 3rem;
    color: #fff;
    text-align: center; }
  .vmd-footer .site-footer {
    background-color: #14345c;
    color: #FFFFFF; }
  .vmd-footer .vmd-footer ul li {
    list-style-type: none;
    margin-bottom: 1rem; }
  .vmd-footer .vmd-footer a {
    color: #fff; }
  .vmd-footer .vmd-footer a:hover {
    cursor: pointer;
    color: #fff; }
  .vmd-footer .vmd-footer .vmd-footer-copyright a {
    font-size: .85rem; }
  .vmd-footer .vmd-footer .vmd-footer-img img {
    height: 30px; }
  .vmd-footer .vmd-footer hr {
    height: 1px;
    background-color: #CEE4EE; }
  .vmd-footer .footer-cta {
    text-align: center; }
  .vmd-footer .footer-cta .footer-cta-title {
    font-family: Gotham_Book, Gotham,Raleway,sans-serif;
    font-size: 3rem; }
  .vmd-footer .footer-cta .vmd-cta-section {
    max-width: 460px;
    margin: 3rem auto 0;
    overflow: auto; }
  .vmd-footer .footer-cta .vmd-cta-section .hs_cos_wrapper {
    margin-right: 1rem;
    margin-bottom: 2rem; }
  .vmd-footer .footer-cta .vmd-cta-section .hs_cos_wrapper + .hs_cos_wrapper {
    margin-right: 0; }
  .vmd-footer .footer-nav-wrap .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-menu-depth-1 {
    float: none; }
  .vmd-footer .footer-nav-wrap .hs-menu-wrapper > ul li.hs-menu-depth-1 > a {
    display: none; }
  .vmd-footer .footer-nav-wrap .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children ul.hs-menu-children-wrapper {
    position: inherit; }
  .vmd-footer .footer-nav-wrap .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-item-has-children ul.hs-menu-children-wrapper li a {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    text-overflow: inherit; }
  @media screen and (min-width: 1024px) {
    .vmd-footer .vmd-footer .col-logo {
      -ms-flex: 0 0 25%;
      -webkit-box-flex: 0;
      flex: 0 0 25%;
      max-width: 25%; }
    .vmd-footer .vmd-footer .footer-nav-wrap {
      -ms-flex: 0 0 75%;
      -webkit-box-flex: 0;
      flex: 0 0 75%;
      max-width: 75%; }
    .vmd-footer .footer-nav-wrap .hs-menu-wrapper.hs-menu-flow-horizontal > ul {
      display: flex;
      margin: 0 -0.75rem; }
    .vmd-footer .footer-nav-wrap .hs-menu-wrapper.hs-menu-flow-horizontal > ul li.hs-menu-depth-1 {
      display: block;
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 0 .75rem; } }
  @media screen and (max-width: 920px) {
    .vmd-footer .section.footer-cta {
      padding-bottom: 0; }
    .vmd-footer .footer-cta .vmd-cta-section .hs_cos_wrapper {
      display: block;
      margin-bottom: 1rem;
      margin-right: 0; }
    .vmd-footer .footer-cta a.cta_button {
      font-size: 1rem !important; }
    .vmd-footer .footer-nav-wrap .hs-menu-wrapper > ul li.hs-menu-depth-1 {
      margin-bottom: 2.5rem; } }

.is-secondary-color-p-3 {
  background-color: #df367c;
  height: 85px;
  text-align: center; }

#primary-callout {
  background-color: #df367c;
  height: 85px;
  text-align: center; }
