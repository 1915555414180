@charset "utf-8";

@font-face {
  font-family: 'Gotham_Bold';
  src: local('Gotham_Bold'), url(../../fonts/gotham/Gotham-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Gotham_Book';
  src: local('Gotham_Book'), url(../../fonts/gotham/Gotham-Book.otf) format('opentype');
}
@font-face {
  font-family: 'Gotham_Medium';
  src: local('Gotham_Medium'), url(../../fonts/gotham/Gotham-Medium.otf) format('opentype');
}

$primaryHeadlineColor : #17335B;
$primaryFormTextColor : #9BA2A2;
$primaryFormBackgroundColor : #F4F6FA;

$progress-bar-background-color: #D3E8EC;
$progress-value-background-color: #0F80A2;
$progress-border-radius: 5px;
$progress-indeterminate-duration: 1.5s;

$successGreen: #2EC1B6;
$success: $successGreen;

$buttonPrimaryColor: $progress-value-background-color;
$buttonDisabledBackgroundColor: #E2E6E6;
$buttonDisabledColor: #9BA2A2;
$buttonHoverBackgroundColor: #189DC5;

@import "../../../node_modules/bulma/bulma.sass";

body {
  margin: 0;
}

html, body, button, input, select, textarea, label {
  font-family: Gotham_Book, arial !important;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body h3.title,
body span.title {
  font-family: Gotham_Bold, arial;
  font-weight: normal;
  font-size: 36px;
  color:$primaryHeadlineColor;
}

body h4.title {
  font-family: Gotham_Bold, arial;
  font-weight: normal;
  font-size: 24px;
  color:$primaryHeadlineColor;
}

body .subtitle {
    font-family: Gotham_Book, arial !important;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 24px;
    color:$primaryHeadlineColor;
    margin: 1rem 0 2rem 0;
    display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-primary {
    color: $primaryHeadlineColor;
}

.vmd-book-appointment-component {
    min-height:400px;
    padding: 1rem;

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border: none;
        -webkit-text-fill-color: $primaryHeadlineColor;
        box-shadow: none;
        transition: background-color 5000s ease-in-out 0s;
    }

    progress.progress {
        margin: 0 0 2rem 0;
    }

    .subtitle.required-text {
        font-size: 1rem;
    }

    .appointment-catetory-header {
        border-bottom: 1px solid #f3f3f3;
        padding: .5rem 0;
        font-size: 2rem;
        font-weight: bold;
        color:#000;
        margin: 1rem 0;
    }
    
    hr {
        margin: 1rem 0 1.5rem 0;
        background-color:$progress-bar-background-color;
    }

    .icon-bandaid {
        margin-bottom: 2rem;
    }

    .icon-refresh {
        margin-right: .5rem;
    }

    .error-description {
        margin-bottom: 4.5rem;
    }

    div.select{
        height: 50px;
        width: 100%;
        margin: 0 0;
        padding: 0;

        select {
            background: $primaryFormBackgroundColor;
            border-radius: 5px;
            border: none;
            color: $primaryFormTextColor;
            height: 100%;
            width: 100%;

            &.has-value {
                color:$primaryHeadlineColor;
            }
        }
        &:not(.is-multiple):not(.is-loading)::after {
            border-color:$primaryHeadlineColor;
            border-width: 1px;

            &:hover {
                color:$primaryHeadlineColor;
            }
        }
    }

    textarea.textarea,
    select.select,
    input.input {
        box-shadow: none;
        background: $primaryFormBackgroundColor;
        border: none;
        border-radius: 5px;
        height: 50px;
        color: $primaryHeadlineColor;
        font-size: 16px;

        &:disabled {
            color: $primaryFormTextColor;
        }

        &.is-danger {
            background: #FF000019;
            border: none;
            
            &:focus,
            &:active {
                border: none;
                box-shadow: none;
            }
        }

        &:-internal-autofill-selected{
            background-color: $primaryFormBackgroundColor !important;
        }
    }

    label.label {
        height: 14px;
        font-family: arial;
        letter-spacing: 0;
        color: #607188;
        font-size: 14px;
        font-weight: normal;
    }

    // TODO: too many buttons lets split this out and refactor
    .button,
    .button:active {
        font-size: 0.75rem;
        background: white;
        color: $buttonPrimaryColor;
        border-radius: 5px;
        border: 1px solid  $buttonPrimaryColor;
        font-weight: bold;
        margin-right: 0;
        padding: .3rem .66rem;
        height: 35px;

        &:focus,
        &:hover {
            background-color: $buttonPrimaryColor;
            color: #fff;
        }
        
        &:focus:not(:active) {
            box-shadow: none;
        }

        &.is-primary-button {
            background-color: $buttonPrimaryColor;
            color: #fff;

            &:focus,
            &:hover {
                background-color: $buttonHoverBackgroundColor;
                color: #fff;
            }

            &:disabled {
                color: $buttonDisabledColor;
                background-color: $buttonDisabledBackgroundColor;
            }
        }

        &.button-large {
            height: 3rem;
        }

        &.is-date {
            background-color: #fafafa;
            border: 1px solid #ddd;
            color: #333;
            border-radius: 5px;
            margin: 0;
            position: relative;

            &:focus,
            &:hover {
                box-shadow: none;
            }
        }

        &.is-secondary-button {
            color:#333;
            border-color: #333;

            &:hover {
                background-color: #333;
                color: #fff;
            }
        }
    }

    .selected-appointment {
        background: $primaryFormBackgroundColor;
        border-radius: 8px;
        padding: 1rem 1rem;
        margin: 0 0 1.5rem 0;
        position: relative;

        .headline {
            margin: 0 0 .5rem 0;
            display: block;
        }
    
        .cancel-appointment {
            position: absolute;
            top: .5rem;
            right: .5rem;
        }
    
        span.label {
            display: block;
            width: 100%;
        }
        span.time {
            font-size: 1.1rem;
            color:#666;
        }

        &.success {
            background: $primaryHeadlineColor;
            color: #fff;

            h4 {
                color:$progress-bar-background-color;
            }
            .label {
                color:#fff;
            }
        }
    }
}
