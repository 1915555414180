.vmd-book-appointment-list-section{
    margin: 1rem -1rem;
    padding: 0 1rem 1.5rem 1rem;
    border-bottom: 2px solid #D3E8EC;

    .empty-slot-placeholder {
        width: auto;
        display: block;
        text-align: center;
        height: auto;
        padding: .5rem 0;
        color: #0F80A2;
        margin: .5rem 0;
    }
    .time {
        font-size: 1.1rem;
        font-weight: bold;
        color:#666;
    }

    .column-date {
        padding: 0 .25rem;
        span {
            font-size: .75rem;
            font-weight: bold;
        }
        .after-threshold {
            display: none;
        }
    }

    .appointment-information {
        padding: 0;
        margin: 10px 0 0 0;
    }

    .provider-information {
        padding: 0 0 0 55px;
        margin: 0 0 1rem 0;
        font-size: 1.15rem;
        font-weight: bold;
        color:#333;
        position: relative;

        .provider-name {
            color:#17335B;
            font-weight: bold;
            font-size: 1.15rem;
        }

        .provider-bio {
            font-size: .85rem;
            font-weight: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            p {
                margin: 0 0 .5rem 0;
            }
        }

        & img { 
            border-radius: 25px;
            position: absolute;
            top: 3px;
            left: 0;
            border: 1px solid #ddd;
            object-fit: cover;
            height: 45px;
            width: 45px;
        }
    }

    .department-name {
        display: block;
        padding: 0 0 0 0;
        margin: 0 0 .5rem 0;
        font-size: 1rem;
        font-weight: normal;
        color:#666;
    }

    .appointment-button-vertical-wrapper {
        button {
            display: block;
            width: 75px;
            margin: .5rem auto .5rem auto;
        }
    }
    .appointment-button-horizontal-wrapper {
        margin: 0 0 0 1rem;
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        scrollbar-width: none;
        -ms-overflow-style: none;

        button {
            display: inline-block;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        .vmd-book-appointment-link + .vmd-book-appointment-link {
            margin-left: .5rem;
        }
    }

    .appointment-date-day { 
        margin: 0 0 .5rem 0;
    }

    .no-availability {
        margin: .25rem 0 0 0;
        color: #ccc;
    }
}
