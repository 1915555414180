.vmd-book-appointment-h-time-control {
    padding: 1rem 0;
    margin: 1rem 0;
    font-size: .85rem;
    color:#333;
    border-bottom: 2px solid #D3E8EC;
    border-top: 2px solid #D3E8EC;

    .center-content {
        text-align: center;
    }
    .section-header {
        text-transform: uppercase;
        color:#17335B;
        font-size: 1rem;
        font-weight: bold;
    }

    button.button.is-date {
        background: transparent;
        border: none;
        outline: none;
        height: 70px;
        width: 75px;

        .appointment-list-time-date-month {
            position: absolute;
            top: 5px;
            left: 25%;
            font-size: 1.15rem;
            text-transform: uppercase;
            font-weight: normal;
            color:#444;
    
            &.active {
                color:#333;
            }
        }   
    
        .appointment-list-time-date-day {
            position: absolute;
            top: 30px;
            padding: 0 .5rem;
            left: 50%;
            transform: translate(-50%);
            margin: 0;
            font-size: 1.5rem;
            color:#444;
            
            &.active {
                color:#333;
                border-radius: 4px;
                border: 1px solid #ddd;
                background: #fafafa;
                left: 50%;
                transform: translate(-50%);
            }
        }

        &:hover {
            span {
                color:rgb(41,175,215);
            }
        }
    }
    
    .shift-days {
        background-color: #fafafa;
        color: #333;
        border: 1px solid #ddd;

        &.shift-days-less {
            margin-left: .75rem;
        }
        &.shift-days-plus {
            margin-right: .75rem;
            float: right;
        }

        &:hover,
        &:focus {
            box-shadow: none;
        }
    }

    .has-valigned-items {
        position: relative;

        button.shift-days {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
    }

    &.mobile-version {
        button.button span.appointment-list-time-date-month,
        button.button span.appointment-list-time-date-day {
            color: #0F80A2;
        }

        .has-valigned-items {
    
            button.shift-days {
                margin: 0;
            }
        }
    }
}

.timezone-title {
    margin-bottom: .5rem;
}
