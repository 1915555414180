$headline-icon-size: 32px;
$provider-photo-size: 45px;

.vmd-book-appointment-checkout {
    .headline-checkout {
        color: #666;

        .headline-icon {
            margin: 0 1rem 0 0;
            height: $headline-icon-size;
            width: $headline-icon-size;
        }

        .headline-text {
            padding: .1rem 0 0 0;
        }
    }

    .checkout-form-wrapper {
        background: #F5F6FA;
        border-radius: 5px;
        padding: 2rem;

        .button, .button:active {
            background: #0F80A2;
            color: #fff;
            font-size: 1rem;
            font-weight: normal;
            margin-top: .5rem;
            height: auto;
        }
        
        .provider-photo {
            position: relative;
            display: block;
            min-width: $provider-photo-size;
            margin: 0 1rem 0 0;

            img {
                border-radius: 25px;
                border: 1px solid #ddd;
                object-fit: cover;
                height: $provider-photo-size;
                width: $provider-photo-size;
            }
        }

        .provider-container {
            align-items: center;
        }
    }
}