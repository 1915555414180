.vmd-book-appointment-confirm {
    .edit-section {
        color:#00BBDC;
        font-family: Gotham_Medium;

        &:hover {
            cursor: pointer;
        }
    }
    div.field.checkbox {
        width: 100%;
        display: block;
        padding: .5rem 0;
    
        
        label {
            height: auto;
            padding: 0 0 0 1.25rem;
            position: relative;
            
            input {
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
            }
            span {
                margin: 0 0 0 0;

                &.checkbox-links {
                    margin: .5rem 0 0 0;
                    display: block;
                }
            }
        }


    }
}