.vmd-book-appointment-user-details {
    .checkbox-control {
        label {
            padding-left: 20px;

            input.checkbox {
                margin-left: -20px;
                margin-right: 8px;
            }
        }
    }

    .guardian-spacer {
        height: 42px;
    }
}