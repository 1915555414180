$react-datepicker-day-selected-color: #0F80A2;
$book-appointment-list-color: #6a6966;
$book-appointment-list-background-color: #f4f6fa;
$is-stick-section-color: #fff;

.vmd-book-appointment-list {
    .vmd-book-appointment-list-datepicker {
        color: $book-appointment-list-color;

        .react-datepicker { font-family: Gotham_Medium, arial; }
        .react-datepicker-wrapper { width: 100%; }
        .react-datepicker-popper { z-index: 1000; }
        .vmd-book-appointment-list-datepicker-calendar {
          
            .vmd-book-appointment-list {
                background: $book-appointment-list-background-color;
            }
            .react-datepicker__day--selected {
                background-color: $react-datepicker-day-selected-color;
            }
        }
        .input {
            width: 100%;
        }
    }

    .is-sticky-section {
        background-color: $is-stick-section-color;
        position: sticky;
        top: 0;
        z-index: 999;
    }

    .filter-location-section {
        padding: 0 1rem 1rem 1rem;
        margin: 0 -1rem 0 -1rem;
        font-size: .85rem;
        z-index: 9999;
    }

    select.vmd-location-select {
        width: 100%;
    }
}
