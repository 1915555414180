.billboard-section {
  background: no-repeat center url('https://www.villagemedical.com/hubfs/woman%20on%20ipad.png');
  background-size: cover;
  height: 100vh;
  align-items: center;
  padding: 2.5rem;
}

.billboard-section-container {
  margin-bottom: 4rem;
}
